import { template as template_642f6fb47b9547ae88cf4ce89ca840e4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_642f6fb47b9547ae88cf4ce89ca840e4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
