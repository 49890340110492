import { template as template_9f6c50340658487d961a897659207db8 } from "@ember/template-compiler";
const FKLabel = template_9f6c50340658487d961a897659207db8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
