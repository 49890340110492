import { template as template_d9c5b4720b3a47d4929b2e8d15999a39 } from "@ember/template-compiler";
const FKText = template_d9c5b4720b3a47d4929b2e8d15999a39(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
